import React, { useRef, useEffect } from "react"
import resl from "resl"
import REGL from "regl"
import { useSpring } from "react-spring"
import { throttle } from "lodash";

import * as simulation from "./water/simulation"
import gradientSrc from "../images/light-gradient-2.png"
import mobileGradientSrc from "../images/light-gradient-2-mobile.png"

import noiseSrc from "../images/noise.png"
import blurFrag from "./water/blur_frag.glsl"
import surfaceFrag from "./water/surface_frag.glsl"
import vert from "./water/vert.glsl"
import { api as viewportApi } from "../hooks/useViewportState"

const isMobile = typeof navigator !== 'undefined' && /iPhone|iPad|iPod|Android/i.test(navigator.userAgent);


const QUAD = {
  positions: [
    [-1, -1, 0],
    [1, -1, 0],
    [1, 1, 0],
    [-1, 1, 0],
  ],
  cells: [
    [0, 1, 2],
    [2, 3, 0],
  ],
}

const midnight = [0.10, 0.11, 0.17, 1.0]
const cream = [0.99, 0.98, 0.95, 1.0]

const WaterComponent = props => {
  let container = useRef()

  let [mouseMoving, setMouseMoving] = useSpring(() => ({
    value: 0,
  }))
  let viewport = viewportApi.getState()

  const springConfig = isMobile
      ? { mass: 1, tension: 100, friction: 20 }
      : { mass: 1, tension: 800, friction: 30 };

  const gradientSource = isMobile
      ? mobileGradientSrc
      : gradientSrc;

  let [mouseSpring, setMouse] = useSpring(() => ({
    xy: [viewport.mouseX, viewport.mouseY],
    config: springConfig,
    onStart: () => {
      setMouseMoving({ value: 1 })
    },
    onRest: () => {
      setMouseMoving({ value: 0 })
    },
  }))

  let { stage } = useSpring({
    stage: props.stage,
    config: {
      mass: 1,
      tension: 200,
      friction: 50,
    },
  })

  useEffect(() => {
    let regl = REGL(container.current)

    let canvasBounds = regl._gl.canvas.getBoundingClientRect()
    let unsubBounds = viewportApi.subscribe(
      bounds => {
        canvasBounds = bounds
      },
      _state => regl._gl.canvas.getBoundingClientRect()
    )

    let [WIDTH, HEIGHT] = simulation.getDimensions(canvasBounds)
    let waterSim = simulation.make({ WIDTH, HEIGHT })
    if (isMobile) {
      WIDTH /= 2;
      HEIGHT /= 2;
    }

    let unsubMouse = viewportApi.subscribe(
        throttle(xy => {
          setMouse({ xy });
          waterSim.updateMouse(
              ~~(((xy[0] - canvasBounds.left) / canvasBounds.width) * WIDTH),
              ~~(((xy[1] - canvasBounds.top) / canvasBounds.height) * HEIGHT)
          );
        }, 100), // Adjust the throttle time based on performance
        state => [state.mouseX, state.mouseY]
    );


    let fbo = regl.framebuffer({
      color: regl.texture({
        width: 1,
        height: 1,
        wrap: "clamp",
      }),
    })

    let drawToFBO = regl({ framebuffer: fbo })

    const drawSurface = regl({
      frag: surfaceFrag,
      vert,
      attributes: {
        position: QUAD.positions,
      },
      elements: QUAD.cells,
      uniforms: {
        time: ({ time }) => time,
        canvas: regl.prop("canvas"),
        mouse: regl.prop("mouse"),
        mouseFactor: regl.prop("mouseFactor"),
        water: regl.prop("water"),
        waterSize: regl.prop("waterSize"),
        gradient: regl.prop("gradient"),
        devicePixelRatio,
        stage: () => stage.getValue(),
      },
    })




    const blur = regl({
      frag: blurFrag,
      vert,
      attributes: {
        position: QUAD.positions,
      },
      elements: QUAD.cells,
      uniforms: {
        canvas: regl.prop("canvas"),
        mouse: regl.prop("mouse"),
        noise: regl.prop("noise"),
        fbo: () => fbo,
        devicePixelRatio,
        time: ({ time }) => time,
        stage: () => stage.getValue(),
      },
    })

    // graphics.current.regl = regl
    let drawFrame = props => {
      let gl = regl._gl
      let canvas = [gl.canvas.width, gl.canvas.height]
      let mouse = mouseSpring.xy.getValue()
      fbo.resize(gl.canvas.width, gl.canvas.height)
      regl.clear({
        color: cream,
        depth: true,
      })
      drawToFBO({}, () => {
        regl.clear({
          color: cream,
          depth: true,
        })
        drawSurface({
          canvas,
          mouse,
          mouseFactor: mouseMoving.value.getValue(),
          water: props.water,
          waterSize: [waterSim.WIDTH, waterSim.HEIGHT],
          noise: props.noise,
          gradient: props.gradient,
        })
      })
      blur({
        canvas,
        mouse,
        noise: props.noise,
      })
    }
    resl({
      manifest: {
        gradient: {
          type: "image",
          src: gradientSource,
          parser: data => regl.texture({ data, min: "linear", mag: "linear" }),
        },
        noise: {
          type: "image",
          src: noiseSrc,
          parser: data =>
            regl.texture({
              data,
              min: "linear",
              mag: "linear",
              wrapS: "repeat",
              wrapT: "repeat",
            }),
        },
      },
      onDone: ({ gradient, noise }) => {
        let waterTexture = regl.texture({
          width: waterSim.WIDTH,
          height: waterSim.HEIGHT,
          data: waterSim.getData(),
          min: "linear",
          mag: "linear",
        })


        regl.frame(() => {
          waterTexture.subimage(waterSim.getData())
          drawFrame({
            gradient,
            noise,
            water: waterTexture,
          })
        })
      },
    })

    return () => {
      unsubBounds()
      unsubMouse()
      try {
        regl.destroy()
      } catch (e) {}
      try {
        waterSim.destroy()
      } catch (e) {}
    }
  }, [])

  return <div ref={container} className={props.className} />
}

export default WaterComponent
