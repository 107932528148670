import React from "react"

import styles from "./clientLogos.module.scss"

let list = [
  ["3M"],
  ["Chipotle"],
  ["Ecolab"],
  ["Fidelity"],
  ["General Mills"],
  ["Hilton"],
  ["Land O’Lakes"],
  ["Nike"],
  ["NMDP"],
  ["Sherwin-Williams"],
  ["Target"],
  ["Thrivent"],
  ["United Healthcare"],
]

export default function ClientLogos() {
  return (
    <div className="wrapper"
         style={{
             marginBottom: '7.5rem',
             marginTop: '7.5rem'

         }}>
      <div
        className="mb-5"
        style={{
          display: "flex",
          alignItems: "center",
        }}
      >
        <span
          style={{ marginRight: "1rem" }}
          className="body-large-after-tablet"
        >
          A few of our friends
        </span>
        <svg height="2px" width="40px" viewBox="0 0 40 2">
          <line
            x1="0px"
            y1="1px"
            x2="40px"
            y2="1px"
            stroke="#1A1C2C"
            strokeWidth="2"
          />
        </svg>
      </div>
      <div className={`${styles.clientName}`}>
        {list.map(([name], i) => {
          return (
            <React.Fragment key={`client-${i}`}>
              {name + (i + 1 >= list.length ? "" : ", ")}
            </React.Fragment>
          )
        })}
      </div>
    </div>
  )
}
